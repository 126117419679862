<template>
    <component
        :is="disabled || loading ? 'button' : as"
        v-tippy="{ content: disabled ? disabledReason : '', trigger: 'mouseenter' }"
        :class="cn(
            'inline-flex items-center justify-center rounded shadow-sm ring-1 text-center font-normal',
            'transition duration-300',
            !disabled && 'hover:shadow-md',
            !disabled && 'focus:shadow-md focus:ring-2',
            disabled && 'cursor-not-allowed opacity-75',

            size === 'sm' && 'h-7 px-1.5 py-1 text-xs',
            size === 'sm' && square && 'w-7 px-1',

            size === 'md' && 'h-9 px-3 py-2 text-xs',
            size === 'md' && square && 'w-9 px-2',

            color === 'tenant' && 'ring-tenant-600 bg-tenant-500 text-tenant-text focus:ring-tenant-500 hover:brightness-125',
            color === 'black' && 'ring-black bg-gray-950 text-white focus:ring-gray-500 hover:bg-gray-800',

            color === 'white' && 'ring-gray-200 bg-white text-gray-700',
            color === 'white' && !disabled && 'hover:bg-gray-50 hover:ring-gray-300 focus:ring-tenant-500 focus:shadow-tenant-500/50 focus:ring-tenant-500',
            color === 'white' && faded && 'bg-gray-50 text-gray-500 hover:bg-white hover:text-gray-600',
            color === 'white' && disabled && 'opacity-100 bg-gray-50 text-gray-500',

            color === 'green' && 'ring-green-600 bg-green-500 text-white shadow-green-800/60 hover:shadow-green-500/30 hover:brightness-110 focus:shadow-green-500/50 focus:ring-green-500',
            color === 'red' && 'ring-red-600 bg-red-500 text-white shadow-red-800/60 hover:shadow-red-500/30 hover:brightness-110 focus:shadow-red-500/50 focus:ring-red-500',
            color === 'blue' && 'ring-blue-600 bg-blue-500 text-white shadow-blue-800/60 hover:shadow-blue-500/30 hover:brightness-110 focus:shadow-blue-500/50 focus:ring-blue-500',
            color === 'teal' && 'ring-teal-600 bg-teal-500 text-white shadow-teal-800/60 hover:shadow-teal-500/30 hover:brightness-110 focus:shadow-teal-500/50 focus:ring-teal-500',
            color === 'purple' && 'ring-purple-600 bg-purple-500 text-white shadow-purple-800/60 hover:shadow-purple-500/30 hover:brightness-110 focus:shadow-purple-500/50 focus:ring-purple-500',

            $attrs.class as ClassValue,
        )"
        :href="href"
        :type="type"
        v-bind="$attrs"
        @click="onClick"
    >
        <div :class="['inline-flex items-center justify-center gap-1.5', loading && 'opacity-0']">
            <slot />
        </div>

        <AspectSpinner
            v-if="loading"
            :light="color !== 'white'"
            class="absolute size-4"
        />
    </component>
</template>

<script lang="ts" setup>
    import { directive as vTippy } from 'vue-tippy';
    import cn from '@aspect/shared/utils/cn.ts';

    import AspectSpinner from './aspect-spinner.vue';

    import type { ClassValue } from 'clsx';

    defineOptions({
        inheritAttrs: false,
    });

    const props = withDefaults(defineProps<{
        type?: string;
        as?: any;
        href?: string;
        color?: 'white' | 'green' | 'red' | 'blue' | 'teal' | 'purple' | 'tenant' | 'black';
        size?: 'sm' | 'md';
        disabled?: boolean;
        disabledReason?: string;
        square?: boolean;
        faded?: boolean;
        loading?: boolean;
    }>(), {
        type: 'button',
        as: 'button',
        href: '',
        color: 'white',
        size: 'md',
    });

    const emit = defineEmits<{
        click: [value: any];
    }>();

    function onClick(value) {
        if (props.disabled || props.loading){
            return;
        }

        emit('click', value);
    }
</script>
